import { useCallback, useId } from 'react';

var useToggleLink = function useToggleLink(open) {
  var id = useId();
  var contentId = "content-".concat(id);
  var getTriggerProps = useCallback(function (_ref) {
    var href = _ref.href;
    return href ? {
      as: "a",
      href: href
    } : {
      'aria-expanded': open,
      'aria-controls': contentId
    };
  }, [open, contentId]);
  var getContentProps = useCallback(function () {
    return {
      id: contentId,
      'aria-hidden': !open
    };
  }, [contentId, open]);
  return {
    getTriggerProps: getTriggerProps,
    getContentProps: getContentProps
  };
};

export default useToggleLink;