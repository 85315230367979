import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _merge from "lodash/merge";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useTheme } from '@emotion/react';
import { useAppearance } from '../foundations/AppearanceContext';

var useDesignTokens = function useDesignTokens(props, defaultTokens, definitions) {
  var _ref = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {},
      _ref$defaultAppearanc = _ref.defaultAppearance,
      defaultAppearance = _ref$defaultAppearanc === void 0 ? "light" : _ref$defaultAppearanc;

  var theme = useTheme();
  var appearance = useAppearance(props.appearance, {
    defaultAppearance: defaultAppearance
  });
  var definition = definitions[appearance];
  if (!definition) throw new Error("No definition for appearance \"".concat(appearance, "\""));
  var definitionTokens = definition(theme, props);

  if (typeof definitionTokens !== "object") {
    throw new Error("Definition for appearance \"".concat(appearance, "\" did not return an object"));
  }

  return _merge(_objectSpread({}, defaultTokens(props, theme)), definitionTokens, props.tokens);
};

export default useDesignTokens;