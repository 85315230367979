import { ChevronDownIcon, ChevronUpIcon } from '@segment/matcha/components/Icons/Common';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var ToggleIcon = function ToggleIcon(_ref) {
  var open = _ref.open;
  var Icon = open ? ChevronUpIcon : ChevronDownIcon;
  return _jsx(Icon, {
    size: "small"
  });
};

export default ToggleIcon;