/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var InfoIcon = createIconsComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  children: _jsx("path", {
    fillRule: "evenodd",
    d: "M12 4c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8Zm1 3v2h-2V7h2Zm-3 9v1h4v-1h-1v-6h-3v1h1v5h-1Z",
    clipRule: "evenodd"
  })
}), 'InfoIcon', 'Common');
export default InfoIcon;