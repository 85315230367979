/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var CheckIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M18.4962 6.32452C19.0902 6.81951 19.1705 7.7023 18.6755 8.29629L11.6755 16.6963C11.4237 16.9984 11.0564 17.1807 10.6634 17.1986C10.2705 17.2164 9.8882 17.0681 9.61 16.79L5.41005 12.59C4.86332 12.0432 4.86332 11.1568 5.41005 10.6101C5.95678 10.0633 6.84321 10.0633 7.38994 10.6101L10.5059 13.726L16.5245 6.50378C17.0195 5.90979 17.9022 5.82954 18.4962 6.32452Z"
  })
}), 'CheckIcon', 'Common');
export default CheckIcon;