import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useCallback, useRef } from 'react';
import useIsomorphicLayout from '../useIsomorphicLayout';
/**
 * A hook to create a memoized callback that will always have the latest version of the callback function
 * @param fn - The function to memoize
 * @param dependencies - The dependencies of the function
 * @returns The memoized callback
 */

var useEventCallback = function useEventCallback(fn, dependencies) {
  var ref = useRef();
  useIsomorphicLayout(function () {
    ref.current = fn;
  }, [fn].concat(_toConsumableArray(dependencies)));
  return useCallback(function () {
    if (ref.current) {
      return ref.current.apply(ref, arguments);
    }
  }, []);
};

export default useEventCallback;