/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ErrorIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM10.8388 7.54547H13.1609L12.9485 14.1055H11.0512L10.8388 7.54547ZM13.2024 15.9336C13.1932 16.5984 12.6346 17.1339 12.0022 17.1339C11.3374 17.1339 10.7926 16.5984 10.8019 15.9336C10.7926 15.2781 11.3374 14.7472 12.0022 14.7472C12.6346 14.7472 13.1932 15.2781 13.2024 15.9336Z"
  })
}), 'ErrorIcon', 'Common');
export default ErrorIcon;