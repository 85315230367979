/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var UpdatesIcon = createIconsComponent(_jsxs("svg", {
  width: 20,
  height: 20,
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: [_jsx("path", {
    d: "M5.1289 19.1219C6.30221 17.9497 6.30221 16.0494 5.1289 14.8773C3.95559 13.7051 2.05329 13.7051 0.87998 14.8773C-0.293327 16.0494 -0.293327 17.9497 0.87998 19.1219C2.05329 20.294 3.95559 20.294 5.1289 19.1219Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3.00444 6.9823C2.47105 6.9823 1.95951 7.19397 1.58234 7.57076C1.20518 7.94754 0.993286 8.45856 0.993286 8.99142C0.993286 9.52427 1.20518 10.0353 1.58234 10.4121C1.95951 10.7889 2.47105 11.0005 3.00444 11.0005C4.5968 11.0015 6.12365 11.6339 7.24962 12.7587C8.37559 13.8835 9.00859 15.4088 9.00958 16.9996C9.01115 17.5282 9.22206 18.0347 9.59622 18.4085C9.97039 18.7823 10.4774 18.993 11.0066 18.9945C11.271 18.9956 11.533 18.9444 11.7775 18.8438C12.022 18.7432 12.2441 18.5953 12.4311 18.4085C12.6181 18.2217 12.7662 17.9998 12.8668 17.7556C12.9675 17.5113 13.0188 17.2496 13.0177 16.9854C13.0173 14.3326 11.9622 11.7885 10.0845 9.91259C8.20669 8.03673 5.66001 6.9827 3.00444 6.9823Z",
    fill: "none"
  }), _jsx("path", {
    d: "M3.00436 0.0210874C2.72028 -0.0194549 2.43079 0.00145698 2.1555 0.0824095C1.88021 0.163362 1.62555 0.302464 1.40876 0.490296C1.19197 0.678127 1.01811 0.910303 0.898956 1.1711C0.779804 1.4319 0.71814 1.71523 0.71814 2.00191C0.71814 2.28858 0.779802 2.57191 0.898954 2.83271C1.01811 3.09351 1.19197 3.32568 1.40876 3.51351C1.62555 3.70134 1.88021 3.84045 2.1555 3.9214C2.43079 4.00235 2.72028 4.02326 3.00436 3.98272C6.45448 3.98891 9.76152 5.36081 12.2011 7.79794C14.6407 10.2351 16.014 13.5388 16.0202 16.9854C16.0191 17.2495 16.0704 17.5113 16.1711 17.7555C16.2718 17.9998 16.4199 18.2217 16.6069 18.4085C16.7938 18.5952 17.016 18.7432 17.2605 18.8438C17.5049 18.9443 17.767 18.9956 18.0314 18.9945C18.5556 18.9855 19.0554 18.7716 19.4235 18.3986C19.7915 18.0256 19.9986 17.5233 20 16.9995C19.9922 12.499 18.1991 8.18495 15.0135 5.00256C11.8279 1.82017 7.5095 0.0288738 3.00436 0.0210874Z",
    fill: "none"
  })]
}), 'UpdatesIcon', 'Common');
export default UpdatesIcon;