import _toConsumableArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/toConsumableArray.js";
import { useEffect } from 'react';
import { serializeStyles } from '@emotion/serialize';
import { StyleSheet } from '@emotion/sheet';
import { serialize, compile, middleware, rulesheet, stringify } from 'stylis';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export var LayoutGlobalStyles = function LayoutGlobalStyles(_ref) {
  var backgroundColor = _ref.backgroundColor;
  return _jsx(Global, {
    styles: "\n      body {\n        background-color: ".concat(backgroundColor, ";\n      }\n    ")
  });
}; // https://github.com/emotion-js/emotion/issues/2131

var Global = function Global(_ref2) {
  var styles = _ref2.styles;
  useEffect(function () {
    var removeStyles = injectGlobal(styles);
    return function () {
      return removeStyles();
    };
  }, [styles]);
  return null;
};

var injectGlobal = function injectGlobal() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var _serializeStyles = serializeStyles.apply(void 0, _toConsumableArray(args)),
      name = _serializeStyles.name,
      styles = _serializeStyles.styles;

  var sheet = new StyleSheet({
    key: "global-".concat(name),
    container: document.body
  });

  var stylis = function stylis(styles) {
    return serialize(compile(styles), middleware([stringify, rulesheet(function (rule) {
      sheet.insert(rule);
    })]));
  };

  stylis(styles);
  return function () {
    return sheet.flush();
  };
};