/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ChevronDownIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M11.3625 16.1418L6.3625 11.1388C6.1375 10.9136 6 10.601 6 10.2507C6 9.56284 6.5625 9 7.25 9C7.6 9 7.9125 9.13758 8.1375 9.36272L12.25 13.4902L16.3625 9.37522C16.5875 9.13758 16.9 9 17.25 9C17.9375 9 18.5 9.56284 18.5 10.2507C18.5 10.601 18.3625 10.9136 18.1375 11.1388L13.1375 16.1418C12.9125 16.3669 12.6 16.5045 12.25 16.5045C11.9 16.5045 11.5875 16.3669 11.3625 16.1418Z",
    fill: "none"
  })
}), 'ChevronDownIcon', 'Common');
export default ChevronDownIcon;