/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ClearIcon = createIconsComponent(_jsx("svg", {
  width: 25,
  height: 24,
  viewBox: "0 0 25 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M17.04 8.707a1 1 0 00-1.414-1.414l-3.293 3.293L9.04 7.293a1 1 0 00-1.414 1.414L10.92 12l-3.293 3.293a1 1 0 101.414 1.414l3.293-3.293 3.293 3.293a1 1 0 001.414-1.414L13.748 12l3.292-3.293z"
  })
}), 'ClearIcon', 'Common');
export default ClearIcon;