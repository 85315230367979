/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var CloseIcon = createIconsComponent(_jsxs("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  children: [_jsx("path", {
    fill: "none",
    d: "M4.293 19.705a1 1 0 0 1 0-1.414L18.29 4.293a1 1 0 1 1 1.414 1.414L5.707 19.705a1 1 0 0 1-1.414 0Z"
  }), _jsx("path", {
    fill: "none",
    d: "M19.705 19.705a1 1 0 0 0 0-1.414L5.707 4.293a1 1 0 0 0-1.414 1.414l13.998 13.998a1 1 0 0 0 1.414 0Z"
  })]
}), 'CloseIcon', 'Common');
export default CloseIcon;