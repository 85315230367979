import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useEffect, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import snippet from '@segment/snippet';
import { withDesignTokens } from '@segment/matcha/useDesignTokens';
import { useTracking } from '@segment/tracking';
import getAbsoluteUrl from '@segment/utils/getAbsoluteUrl';
import { usePageConfig } from '@segment/contexts/page';
import * as views from './Layout.views'; // We want to import favicons via file-loader to make sure webpack doesn't inline them

import appleIcon57 from './assets/apple-icon-57x57.png?url';
import appleIcon60 from './assets/apple-icon-60x60.png?url';
import appleIcon72 from './assets/apple-icon-72x72.png?url';
import appleIcon76 from './assets/apple-icon-76x76.png?url';
import appleIcon114 from './assets/apple-icon-114x114.png?url';
import appleIcon120 from './assets/apple-icon-120x120.png?url';
import appleIcon144 from './assets/apple-icon-144x144.png?url';
import appleIcon152 from './assets/apple-icon-152x152.png?url';
import androidIcon192 from './assets/android-icon-192x192.png?url';
import favicon32 from './assets/favicon-32x32.png?url';
import favicon96 from './assets/favicon-96x96.png?url';
import favicon16 from './assets/favicon-16x16.png?url';
import msIcon144 from './assets/ms-icon-144x144.png?url';
import SegmentOgImage from './assets/SegmentOgImage.jpg';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";

var usePageLoadEventTracking = function usePageLoadEventTracking(pageTitle) {
  var _useRouter = useRouter(),
      events = _useRouter.events;

  var _usePageConfig = usePageConfig(),
      baseUrl = _usePageConfig.baseUrl;

  useEffect(function () {
    var handleRouteChange = function handleRouteChange(urlPath) {
      var _window$analytics;

      (_window$analytics = window.analytics) === null || _window$analytics === void 0 ? void 0 : _window$analytics.page(pageTitle, {
        path: urlPath,
        url: "".concat(baseUrl).concat(urlPath)
      });
    };

    events.on("routeChangeComplete", handleRouteChange); // If the component is unmounted, unsubscribe from the event

    return function () {
      events.off("routeChangeComplete", handleRouteChange);
    };
  }, [events, pageTitle, baseUrl]);
};

var useTrackReferer = function useTrackReferer() {
  var _useRouter2 = useRouter(),
      query = _useRouter2.query;

  var _useTracking = useTracking(),
      track = _useTracking.track;

  useEffect(function () {
    if (query !== null && query !== void 0 && query.email) {
      var _document$referrer, _document;

      track("Debug Email Parameter in URL", {
        referer: (_document$referrer = (_document = document) === null || _document === void 0 ? void 0 : _document.referrer) !== null && _document$referrer !== void 0 ? _document$referrer : ""
      }, false, "client");
    }
  }, [query, track]);
};

var ASSETS_URL = "images.ctfassets.net";

var getOgUrl = function getOgUrl(baseUrl, path) {
  var host = baseUrl || "http://localhost:3000";
  var pathWithoutQueryString = path.split("?")[0];
  var pathWithTrailingSlash = pathWithoutQueryString.endsWith("/") ? pathWithoutQueryString : "".concat(pathWithoutQueryString, "/");
  return "".concat(host).concat(pathWithTrailingSlash);
};

var BaseLayout = function BaseLayout(_ref) {
  var _process$env$GOOGLE_S;

  var pageTitle = _ref.pageTitle,
      _ref$pageDescription = _ref.pageDescription,
      pageDescription = _ref$pageDescription === void 0 ? "" : _ref$pageDescription,
      _ref$pageOG = _ref.pageOG,
      pageOG = _ref$pageOG === void 0 ? SegmentOgImage : _ref$pageOG,
      pageRobotsDirective = _ref.pageRobotsDirective,
      canonicalUrl = _ref.canonicalUrl,
      themeColor = _ref.themeColor,
      children = _ref.children,
      tokens = _ref.tokens;
  usePageLoadEventTracking(pageTitle);
  useTrackReferer();
  var theme = useTheme();
  var pageConfig = usePageConfig();
  var baseUrl = pageConfig.baseUrl;
  var ogImageUrl = pageOG !== null && pageOG !== void 0 && pageOG.includes(ASSETS_URL) ? pageOG : "".concat(baseUrl).concat(pageOG);
  var googleVerificationIds = (_process$env$GOOGLE_S = process.env.GOOGLE_SITE_VERIFICATION) !== null && _process$env$GOOGLE_S !== void 0 ? _process$env$GOOGLE_S : "";

  var _useRouter3 = useRouter(),
      asPath = _useRouter3.asPath;

  var ogUrl = getOgUrl(baseUrl, asPath);
  useEffect(function () {
    var untypedWindow = window; // Ensures the optimizely object is defined globally

    untypedWindow.optimizely = untypedWindow.optimizely || []; // Set the waitForOriginSync API call
    // place your own domains in the canonicalOrigins array

    untypedWindow.optimizely.push({
      type: "waitForOriginSync",
      canonicalOrigins: ["segment.com", "cdpweek.com", "preview.segment.build"]
    });
  }, []);
  var analyticsJsMinified = useMemo(function () {
    return process.env.SEGMENT_ANALYTICS_WRITEKEY ? snippet.min({
      apiKey: process.env.SEGMENT_ANALYTICS_WRITEKEY,
      host: "ajs.cd.segment.com",
      load: false,
      page: {
        name: pageTitle
      }
    }) : "";
  }, [pageTitle]);
  return _jsxs(_Fragment, {
    children: [_jsxs(Head, {
      children: [_jsx("title", {
        children: pageTitle
      }), _jsx("meta", {
        property: "og:title",
        content: pageTitle
      }), _jsx("meta", {
        property: "og:site_name",
        content: "Segment"
      }), _jsx("meta", {
        property: "og:url",
        content: ogUrl
      }), _jsx("meta", {
        property: "og:type",
        content: "website"
      }), _jsx("meta", {
        name: "robots",
        content: pageRobotsDirective
      }), _jsx("meta", {
        name: "twitter:title",
        content: pageTitle
      }), _jsx("meta", {
        name: "twitter:site",
        content: "@segment"
      }), _jsx("meta", {
        name: "twitter:card",
        content: "summary_large_image"
      }), _jsx("meta", {
        property: "og:image",
        content: ogImageUrl
      }), _jsx("meta", {
        name: "twitter:image",
        content: ogImageUrl
      }), _jsx("meta", {
        name: "description",
        content: pageDescription
      }), _jsx("meta", {
        name: "twitter:description",
        content: pageDescription
      }), _jsx("meta", {
        property: "og:description",
        content: pageDescription
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "57x57",
        href: appleIcon57
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "60x60",
        href: appleIcon60
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "72x72",
        href: appleIcon72
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "76x76",
        href: appleIcon76
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "114x114",
        href: appleIcon114
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "120x120",
        href: appleIcon120
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "144x144",
        href: appleIcon144
      }), _jsx("link", {
        rel: "apple-touch-icon",
        sizes: "152x152",
        href: appleIcon152
      }), _jsx("link", {
        rel: "icon",
        type: "image/png",
        sizes: "192x192",
        href: androidIcon192
      }), _jsx("link", {
        rel: "icon",
        type: "image/png",
        sizes: "32x32",
        href: favicon32
      }), _jsx("link", {
        rel: "icon",
        type: "image/png",
        sizes: "96x96",
        href: favicon96
      }), _jsx("link", {
        rel: "icon",
        type: "image/png",
        sizes: "16x16",
        href: favicon16
      }), _jsx("meta", {
        name: "msapplication-TileImage",
        content: msIcon144
      }), _jsx("link", {
        rel: "manifest",
        href: getAbsoluteUrl("/manifest.json", pageConfig)
      }), _jsx("link", {
        rel: "canonical",
        href: canonicalUrl !== null && canonicalUrl !== void 0 ? canonicalUrl : "".concat(baseUrl).concat(asPath)
      }), _jsx("meta", {
        name: "msapplication-TileColor",
        content: theme.palette.brand.segmentGreen[100]
      }), _jsx("meta", {
        name: "theme-color",
        content: themeColor
      }), _jsx("meta", {
        name: "facebook-domain-verification",
        content: process.env.FACEBOOK_DOMAIN_VERIFICATION
      }), googleVerificationIds.split(",").map(function (id, idx) {
        return _jsx("meta", {
          name: "google-site-verification",
          content: id
        }, idx);
      }), _jsx("script", {
        async: true,
        dangerouslySetInnerHTML: {
          __html: analyticsJsMinified
        }
      })]
    }), _jsx(views.LayoutGlobalStyles, _objectSpread({}, tokens)), children]
  });
};

export default withDesignTokens(BaseLayout, function () {
  return {};
}, {
  light: function light(theme, props) {
    return {
      backgroundColor: props.bodyBackground || theme.palette.neutrals.cloud[100]
    };
  },
  dark: function dark(theme, props) {
    return {
      backgroundColor: props.bodyBackground || theme.palette.brand.darkAcai[700]
    };
  },
  twilioLight: function twilioLight(theme, props) {
    return {
      backgroundColor: props.bodyBackground || theme.palette.neutrals.white[100]
    };
  },
  twilioDark: function twilioDark(theme, props) {
    return {
      backgroundColor: props.bodyBackground || theme.palette.twilio.gray[100]
    };
  },
  cdpWeek2023: function cdpWeek2023(theme, props) {
    return {
      backgroundColor: props.bodyBackground || theme.palette.cdpWeek2023.gray[100]
    };
  }
});