import _isEmpty from "lodash/isEmpty";
import { useEffect, useState } from 'react';
/**
 * Window.matchMedia browser API closed in React hook. It triggers component
 * renders on media query change event
 *
 * @param mediaQuery - media query string without '@media' prefix eg. (min-width: 1000px)
 * @param initialValue - needed for SSR
 */

var useMatchMedia = function useMatchMedia(mediaQuery, initialValue) {
  var _useState = useState(mediaQuery ? initialValue : false),
      isMatching = _useState[0],
      setIsMatching = _useState[1];

  useEffect(function () {
    if (!_isEmpty(mediaQuery)) {
      var watcher = window.matchMedia(mediaQuery);
      setIsMatching(watcher.matches);

      var listener = function listener(matches) {
        if (mediaQuery && mediaQuery.length > 0) {
          setIsMatching(matches.matches);
        } else {
          setIsMatching(false);
        }
      };

      if (watcher.addEventListener) {
        watcher.addEventListener("change", listener);
      } else {
        watcher.addListener(listener);
      }

      return function () {
        if (watcher.removeEventListener) {
          return watcher.removeEventListener("change", listener);
        }

        return watcher.removeListener(listener);
      };
    }
  }, [mediaQuery]);
  return isMatching;
};

export default useMatchMedia;