/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ChevronRightIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M16.2274 11.1125L11.2245 6.1125C10.9993 5.8875 10.6866 5.75 10.3364 5.75C9.64853 5.75 9.08569 6.3125 9.08569 7C9.08569 7.35 9.22328 7.6625 9.44841 7.8875L13.5759 12L9.46092 16.1125C9.22328 16.3375 9.08569 16.65 9.08569 17C9.08569 17.6875 9.64853 18.25 10.3364 18.25C10.6866 18.25 10.9993 18.1125 11.2245 17.8875L16.2274 12.8875C16.4526 12.6625 16.5902 12.35 16.5902 12C16.5902 11.65 16.4526 11.3375 16.2274 11.1125Z",
    fill: "none"
  })
}), 'ChevronRightIcon', 'Common');
export default ChevronRightIcon;