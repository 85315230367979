import { useEffect, useLayoutEffect } from 'react';
/**
 * A hook to use either useEffect or useLayoutEffect depending on the environment
 * @param effect - The function to be called
 * @param deps - The dependencies for the function
 */

var useIsomorphicLayout = function useIsomorphicLayout(effect, deps) {
  var hook = false ? useEffect : useLayoutEffect;
  hook(effect, deps);
};

export default useIsomorphicLayout;