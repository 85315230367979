/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ArrowUpRight = createIconsComponent(_jsx("svg", {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24",
  fill: "none",
  children: _jsx("path", {
    d: "M19.205 12.519a.75.75 0 0 0 1.5.023l.126-8.146a1.194 1.194 0 0 0-.346-.881 1.182 1.182 0 0 0-.881-.346l-8.146.126a.75.75 0 1 0 .023 1.5l6.768-.105L3.515 19.425a.75.75 0 0 0 1.06 1.06L19.31 5.751l-.105 6.768Z"
  })
}), 'ArrowUpRight', 'Common');
export default ArrowUpRight;