/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ArrowLeft = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M2.27287 12.6866L8.76721 19.6805C9.14301 20.0852 9.77575 20.1086 10.1805 19.7328C10.5852 19.357 10.6086 18.7243 10.2328 18.3196L5.29322 13H21C21.5523 13 22 12.5523 22 12C22 11.4477 21.5523 11 21 11H5.29321L10.2328 5.68046C10.6086 5.27574 10.5852 4.64301 10.1805 4.26721C9.77575 3.89141 9.14301 3.91484 8.76721 4.31955L2.26896 11.3177C2.10211 11.4964 2 11.7363 2 12C2 12.1448 2.03079 12.2825 2.08619 12.4068C2.13104 12.5077 2.19333 12.6025 2.27287 12.6866Z",
    fill: "none"
  })
}), 'ArrowLeft', 'Common');
export default ArrowLeft;