import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children", "open"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { AnimatePresence, m } from 'framer-motion';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var ToggleLinkContent = function ToggleLinkContent(_ref) {
  var children = _ref.children,
      open = _ref.open,
      rest = _objectWithoutProperties(_ref, _excluded);

  return _jsx("div", _objectSpread(_objectSpread({}, rest), {}, {
    children: _jsx(AnimatePresence, {
      initial: false,
      children: open && _jsx(m.div, {
        initial: "collapsed",
        animate: "open",
        exit: "collapsed",
        variants: {
          open: {
            opacity: 1,
            height: "auto"
          },
          collapsed: {
            opacity: 0,
            height: 0
          }
        },
        transition: {
          duration: 0.2
        },
        children: children
      })
    })
  }));
};

export default ToggleLinkContent;