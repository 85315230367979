/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ArrowRight = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M21.7271 12.6866L15.2328 19.6805C14.857 20.0852 14.2243 20.1086 13.8195 19.7328C13.4148 19.357 13.3914 18.7243 13.7672 18.3196L18.7068 13H3C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H18.7068L13.7672 5.68046C13.3914 5.27574 13.4148 4.64301 13.8195 4.26721C14.2243 3.89141 14.857 3.91484 15.2328 4.31955L21.731 11.3177C21.8979 11.4964 22 11.7363 22 12C22 12.1448 21.9692 12.2825 21.9138 12.4068C21.869 12.5077 21.8067 12.6025 21.7271 12.6866Z",
    fill: "none"
  })
}), 'ArrowRight', 'Common');
export default ArrowRight;