/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ChevronLeftIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M7.94394 11.1125L12.9469 6.1125C13.1721 5.8875 13.4847 5.75 13.8349 5.75C14.5229 5.75 15.0857 6.3125 15.0857 7C15.0857 7.35 14.9481 7.6625 14.723 7.8875L10.5955 12L14.7105 16.1125C14.9481 16.3375 15.0857 16.65 15.0857 17C15.0857 17.6875 14.5229 18.25 13.8349 18.25C13.4847 18.25 13.1721 18.1125 12.9469 17.8875L7.94394 12.8875C7.71881 12.6625 7.58123 12.35 7.58123 12C7.58123 11.65 7.71881 11.3375 7.94394 11.1125Z",
    fill: "none"
  })
}), 'ChevronLeftIcon', 'Common');
export default ChevronLeftIcon;