/*!
 * ==============================================================
 * This file is generated automatically by the generator.
 * DO NOT UPDATE IT MANUALLY!
 * ==============================================================
 */
import createIconsComponent from '../../utils/createIconsComponent';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ChevronUpIcon = createIconsComponent(_jsx("svg", {
  width: 24,
  height: 24,
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  children: _jsx("path", {
    d: "M11.3625 7.8582L6.3625 12.8612C6.1375 13.0863 6 13.399 6 13.7492C6 14.4371 6.5625 15 7.25 15C7.6 15 7.9125 14.8624 8.1375 14.6372L12.25 10.5098L16.3625 14.6247C16.5875 14.8624 16.9 15 17.25 15C17.9375 15 18.5 14.4371 18.5 13.7492C18.5 13.399 18.3625 13.0863 18.1375 12.8612L13.1375 7.8582C12.9125 7.63307 12.6 7.49548 12.25 7.49548C11.9 7.49548 11.5875 7.63307 11.3625 7.8582Z",
    fill: "none"
  })
}), 'ChevronUpIcon', 'Common');
export default ChevronUpIcon;