/**
 * Returns an absolute URL for a given path.
 * @param path - The path to convert to an absolute URL.
 * @param options - The options to use when converting the path to an absolute URL.
 * @param options.baseUrl - The base URL to use for the absolute URL.
 * @param options.basePath - The path prefix to use for the absolute URL.
 * @returns The absolute URL.
 * @example
 * getAbsoluteUrl('/blog', { baseUrl: 'https://segment.com', basePath: '/matcha' }) // => 'https://segment.com/matcha/blog'
 */
var getAbsoluteUrl = function getAbsoluteUrl(path, _ref) {
  var baseUrl = _ref.baseUrl,
      basePath = _ref.basePath;

  if (!path.startsWith("/")) {
    return path;
  }

  return "".concat(baseUrl).concat(basePath).concat(path);
};

export default getAbsoluteUrl;