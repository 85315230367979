import ChevronDownIcon from './assets/ChevronDown.svg';
import ChevronUpIcon from './assets/ChevronUp.svg';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";

var ToggleIcon = function ToggleIcon(_ref) {
  var open = _ref.open;
  var Icon = open ? ChevronUpIcon : ChevronDownIcon;
  return _jsx(Icon, {});
};

export default ToggleIcon;